<template>
  <div class="channel-template">
    <div class="page">
        <div class="row">
            <div class="col-md-4 col-lg-3">
              <ul class="page-sidebar">
                <li class="p-3 border-bottom" style="font-weight: 700;">{{ channel.name }}</li>
                  <li v-bind:class="setActiveSidebar('integration')">
                      <b-link :to="{name: 'callcenter.channel', params: {channelId: channel.id}}">
                          How to use?
                      </b-link>
                  </li>
                  <li v-if="user && user.hasPermission('rtc:channel.update')" v-bind:class="setActiveSidebar('manageChannel')">
                      <b-link :to="{name: 'callcenter.channel.edit', params: {channelId: channel.id}}">
                          Edit channel
                      </b-link>
                  </li>
                  <li v-if="user && user.hasPermission('rtc:channel.user.assign')" v-bind:class="setActiveSidebar('manageUsers')">
                      <b-link :to="{name: 'callcenter.channel.users', params: {channelId: channel.id}}">
                          Channel access
                      </b-link>
                  </li>

                  <li v-if="user && user.hasPermission('rtc:channel.update')" v-bind:class="setActiveSidebar('origins')">
                      <b-link :to="{name: 'callcenter.channel.origins', params: {channelId: channel.id}}">
                          Manage origins
                      </b-link>
                  </li>
                  <li v-if="user && user.hasPermission('rtc:channel.delete')" v-bind:class="setActiveSidebar('delete')">
                      <b-link :to="{name: 'callcenter.channel.delete', params: {channelId: channel.id}}">
                          Delete channel
                      </b-link>
                  </li>
              </ul>
            </div>
            <div class="col-md-8 ml-sm-auto col-lg-9 px-md-4">
                <slot></slot>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    props: ['active', 'channel', 'breadcrumbs', 'page','user'],
    components: {

    },
    methods: {
        setActiveSidebar(name){
            return this.active === name ? 'active' : '';
        },
    },
};
</script>
