<template>
  <div class="channel-settings">
    <ChannelTemplate v-if="channel" :user="user" :channel="channel" :active="activeSidebar">
      <router-view :channel="channel" @set-channel="setChannel" @on-channel-update="onChannelUpdate" :user="user"></router-view>
    </ChannelTemplate>
    <div v-else><b-spinner label="Loading..."></b-spinner></div>
  </div>
</template>

<script>
import ChannelTemplate from './../../../components/Channel.Template';
export default {
    props: ['user'],
    components: {
      ChannelTemplate
    },
    data(){
        return {
          isLoading: true,
          channel: null,
          activeSidebar: "integration"
        };
    },
    methods:{
      onChannelUpdate(channel){
        this.channel = channel;
      },
      setChannel(data){
        this.activeSidebar = data.activeSidebar;
      },
      notifyParent(){
        let data = {active: null};
        this.$emit('set-sidebar',data);
        this.$emit('set-navbar',data);
      },

      getChannel(){
        this.isLoading = true;
        this.$api.get(`channel/${this.$route.params.channelId}`).then(res => {
          this.isLoading = false;
          this.channel = res.data;
        });
      },
    },

    mounted(){
        this.notifyParent();
        this.getChannel();
    }
};
</script>
